import { CART_ITEM_TYPE } from 'enum/cart-item-type'
import {
    CART_GENERIC_PRODUCT_LS_KEY,
    CART_LS_KEY,
    IGenericProductLineItem,
    IKitLineItem,
    IMedicineLineItem,
    KIT_LS_KEY,
} from 'lib/cart'
import { JAR_PILL_VARIANT_REGEXP } from './sku-querying'
import * as Sentry from '@sentry/nextjs'
import { getItemFromLS, setItemInLS } from './storage'
import { decodeBs64 } from './base64-converter'

export const pouchToQuantityCartKitStructUpdateIdentifier = 2

export const CART_GENERIC_LINE_ITEM_MODIFIED_LS_KEY = 'cart_generic_line_item_modified'
export const cartGenericLineItemCanUpdateQuantityModifiedIdentifier = 1
export const cartGenericLineItemModifiedToShopify_V_2022_10Identifier = 2

export const migrateCartLineItemToShopify_V_2022_10Identifier = 1

export const CART_GENERIC_LINE_ITEM_MIGRATED_TO_SHOPIFY_V_2022_10 =
    'cart_generic_line_items_migrated_to_shopify_v_2022_10'

export const CART_V2_CLEARED_LS_KEY = 'cart_v2_cleared'

export const getLineItemsFromKitLineItem = (kitLineItem: IKitLineItem): IMedicineLineItem[] => {
    const lineItems: IMedicineLineItem[] = []

    for (const product of kitLineItem?.products) {
        const jarVariant = product.shopifyProduct?.variants?.edges?.find(({ node }) =>
            JAR_PILL_VARIANT_REGEXP.test(node?.sku),
        )

        // push as a separate line item for each quantity
        // this preserves jar variant for each kit
        for (let i = 0; i < kitLineItem.quantity; i++) {
            lineItems.push({
                ...product,
                type: CART_ITEM_TYPE.MEDICINE,
                id: product.shopifyProduct.id,
                variantId: jarVariant?.node?.id,
                productName: product.cabinetDrugName,
                thumbnail: kitLineItem.thumbnail,
                quantity: product.quantity,
                variants: product.shopifyProduct?.variants?.edges?.map((variant) => variant?.node),
                available: jarVariant?.node?.availableForSale,
                slug: product.slug,
                sku: jarVariant?.node?.sku,
            })
        }
    }

    return lineItems
}

/**
 * Sets canUpdateQuantity to true for recycling bag
 * @returns void
 */
export const setCanUpdateQuantityPropertyForRecyclingBagLineItem = (): void => {
    try {
        const genericProductLineItemsString = getItemFromLS(CART_GENERIC_PRODUCT_LS_KEY)

        if (!genericProductLineItemsString) {
            return
        }

        const genericProductLineItems: IGenericProductLineItem[] = JSON.parse(genericProductLineItemsString)

        const updatedGenericProductLineItems = genericProductLineItems.map((item) => {
            if (item?.variant?.sku === 'CAB-POUCH') {
                item.canUpdateQuantity = false
            }

            return item
        })

        setItemInLS(CART_GENERIC_PRODUCT_LS_KEY, JSON.stringify(updatedGenericProductLineItems))
        setItemInLS(
            CART_GENERIC_LINE_ITEM_MODIFIED_LS_KEY,
            JSON.stringify(cartGenericLineItemCanUpdateQuantityModifiedIdentifier),
        )
    } catch (e) {
        Sentry.captureException(e)
    }
}

/**
 * Migrate to Shopify v2022-10
 */
export const migrateCartGenericLineItemsToUseShopifyV_2022_10 = (): void => {
    try {
        const cartGenericLineItems = JSON.parse(
            getItemFromLS(CART_GENERIC_PRODUCT_LS_KEY) ?? '[]',
        ) as IGenericProductLineItem[]

        cartGenericLineItems?.forEach((lineItem) => {
            lineItem.id = decodeBs64(lineItem.id)
            lineItem.variantId = decodeBs64(lineItem.variantId)

            delete lineItem['priceV2']
            delete lineItem['compareAtPriceV2']

            lineItem.variant.id = decodeBs64(lineItem.variant.id)
            lineItem.variant.price = lineItem.variant['priceV2']
            lineItem.variant.compareAtPrice = lineItem.variant['compareAtPrice']

            delete lineItem.variant['priceV2']
            delete lineItem.variant['compareAtPriceV2']
        })

        setItemInLS(CART_GENERIC_PRODUCT_LS_KEY, JSON.stringify(cartGenericLineItems ?? []))
    } catch (e) {
        Sentry.captureException(e)
    }

    setItemInLS(
        CART_GENERIC_LINE_ITEM_MODIFIED_LS_KEY,
        JSON.stringify(cartGenericLineItemModifiedToShopify_V_2022_10Identifier),
    )
}

/**
 * Migrate to Shopify v2022-10
 */

export const clearCart = (): void => {
    try {
        setItemInLS(CART_LS_KEY, '[]')
        setItemInLS(KIT_LS_KEY, '[]')
        setItemInLS(CART_GENERIC_PRODUCT_LS_KEY, '[]')
    } catch {}
}
