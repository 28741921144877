import { createRemoteCheckout, createRemoteCart, applyCouponToCheckout } from 'lib/api/checkout'
import { IShopifyMoney, IShopifyProductVariant } from 'interfaces/product'
import { getCurrentProductPricesAndInventory } from 'lib/api/products'
import { IImage } from 'interfaces/media'
import { CART_ITEM_TYPE } from 'enum/cart-item-type'
import { IKitMedicine } from 'interfaces/medicine'
import { JAR_PILL_VARIANT_REGEXP } from './util/sku-querying'
import { getLineItemsFromKitLineItem } from './util/cart'
import { IShopifyDiscountAllocation } from 'interfaces/product'
import { IUser } from 'interfaces/user'
import { ICoupon } from 'interfaces/coupon'
import { removeItemFromLS, setItemInLS } from './util/storage'
import * as Sentry from '@sentry/nextjs'

export const CART_LS_KEY = 'cart'
export const KIT_LS_KEY = 'cart_kit'
export const REMOTE_CHECKOUT_LS_KEY = 'remote_checkout'
export const COUPON_LS_KEY = 'coupon'
export const CHECKOUT_TIMESTAMP_LS_KEY = 'cart_checkout_timestamp'
export const CART_VALIDITY_PERIOD_AFTER_CHECKOUT = 14 * 24 * 60 * 60 * 1000
export const CART_GENERIC_PRODUCT_LS_KEY = 'cart_generic_product'

export interface IShopifyCheckout {
    id: string
    lineItems: {
        edges: {
            node: {
                id?: string
                quantity?: number
                discountAllocations: IShopifyDiscountAllocation[]
                variant: {
                    __typename: 'ProductVariant'
                    id: string
                    title: string
                    price: {
                        amount
                        currencyCode
                    }
                }
            }
        }[]
    }
    webUrl: string
    discountApplications: {
        edges: {
            node: {
                code: string
                value: {
                    __typename: 'PricingPercentageValue'
                    percentage: number
                }
                __typename: 'DiscountCodeApplication'
            }
            __typename: 'DiscountApplicationEdge'
        }[]
        __typename: 'DiscountApplicationConnection'
    }
    totalPrice: IShopifyMoney
    lineItemsSubtotalPrice: IShopifyMoney
}

interface IShopifyCart {
    id: string
    checkoutUrl: string
}

export interface ILineItem {
    type: CART_ITEM_TYPE
    sku: string
    quantity: number
    id: string
    productName: string
    thumbnail: IImage
    variantId?: string
    available?: boolean
    canUpdateQuantity?: boolean
}

export interface IMedicineLineItem extends ILineItem {
    type: CART_ITEM_TYPE.MEDICINE
    variants: IShopifyProductVariant[]
    pillType: string
    pillTypePlural?: string
    slug: string
    discountAllocations?: IShopifyDiscountAllocation[]
    discountedPrice?: number
    sellingPlan?: string
}

export interface IKitLineItem extends ILineItem {
    type: CART_ITEM_TYPE.KIT
    slug: string
    products: IKitMedicine[]
    price: number
    discountedPrice?: number
}

export interface IGenericProductLineItem extends ILineItem {
    type: CART_ITEM_TYPE.GENERIC_PRODUCT
    slug: string
    variant: IShopifyProductVariant
    variantId: string
}

export type IAllLineItems = (IMedicineLineItem | IKitLineItem | IGenericProductLineItem)[]

/**
 * Stubbing out a basic cart class
 * */
export default class Cart {
    lineItemsArr: IMedicineLineItem[]
    kitLineItemsArr: IKitLineItem[]
    remoteCart: IShopifyCart
    remoteCheckout: IShopifyCheckout
    currentUser: IUser
    currentIdentity: string // email
    coupon: ICoupon
    genericProductLineItemsArr: IGenericProductLineItem[]

    constructor() {
        this.lineItemsArr = []
        this.kitLineItemsArr = []
        this.genericProductLineItemsArr = []
    }

    // Convert line items to an array and return
    get lineItems(): IMedicineLineItem[] {
        // return cloned objects
        return this.lineItemsArr.map((lineItem) => ({ ...lineItem }))
    }

    /**
     * Merge individual, kit line items
     */
    get allCartItemsAsLineItem(): IAllLineItems {
        // this technically is not IAllLineItems, can be further
        // refactored to a generic type of all line items
        let lineItems: IAllLineItems = [...this.lineItems, ...this.genericProductLineItems]

        // put medicines from kit as line items
        for (const kitLineItem of this.kitLineItems) {
            const lineItemsInKit = getLineItemsFromKitLineItem(kitLineItem)
            lineItems = [...lineItems, ...lineItemsInKit]
        }

        return lineItems
    }

    // Set the cart's email / identity
    async setIdentity(identity?: string): Promise<void> {
        if (identity) {
            this.currentIdentity = identity
            // Create a new checkout with the email attached for abandoned checkouts
            this.createCheckout()
            // Update the cart w/ identity so customer doesn't have to enter email
            this.createCart()
        }
    }

    getCheckoutLineItem(variantId: string): IShopifyCheckout['lineItems']['edges'][0]['node'] {
        return this.remoteCheckout?.lineItems.edges.find((edge) => edge.node.variant.id === variantId)[0] ?? {}
    }

    // return particular line item
    getLineItem(variantId: string): [IMedicineLineItem, number] {
        let lineItemIndex = -1

        const lineItem = this.lineItemsArr.find((lineItem, index) => {
            if (lineItem.variantId === variantId) {
                lineItemIndex = index
                return true
            }
        })

        return [lineItem === undefined ? undefined : { ...lineItem }, lineItemIndex]
    }

    // Update cart with latest inventory + prices
    async updatePricesAndInventory(): Promise<void> {
        const medicineLineItemVariantIds = this.lineItems.map((lI) => lI.variantId)
        const kitMedicineVariantIds = this.kitLineItems.map((kit) =>
            // first variant is jar variant
            kit.products?.map((product) => {
                const jarVariant = product.shopifyProduct?.variants?.edges?.find((variant) =>
                    JAR_PILL_VARIANT_REGEXP.test(variant?.node?.sku),
                )
                return jarVariant?.node?.id
            }),
        )
        const genericProductVariantIds = this.genericProductLineItems.map((lI) => lI.variantId)

        const allVariantIds = [
            ...medicineLineItemVariantIds,
            ...kitMedicineVariantIds.flat(),
            ...genericProductVariantIds,
        ]

        if (allVariantIds.length) {
            const latestProductDetails = await getCurrentProductPricesAndInventory(allVariantIds)
            const updatedLineItemsArray = this.lineItemsArr.map((lineItem) => {
                const matchedLineItem = latestProductDetails.find((product) => product?.id === lineItem.variantId)

                // find and update variant from lineItem.variants array
                const updatedVariants = lineItem.variants.map((variant) => {
                    if (variant.id === matchedLineItem?.id) {
                        return {
                            ...variant,
                            ...matchedLineItem,
                        }
                    }

                    return variant
                })

                return {
                    ...lineItem,
                    ...matchedLineItem,
                    variants: updatedVariants,
                }
            })

            this.lineItemsArr = updatedLineItemsArray

            // update each medicine in kit line item with the
            // latest shopify details
            for (const kitLineItem of this.kitLineItemsArr) {
                // start recount
                kitLineItem.price = 0

                for (const product of kitLineItem?.products) {
                    const jarVariant = product.shopifyProduct?.variants?.edges?.find((variant) =>
                        JAR_PILL_VARIANT_REGEXP.test(variant?.node?.sku),
                    )

                    const updatedJarVariant = latestProductDetails.find((p) => p?.id === jarVariant?.node?.id)

                    jarVariant.node = {
                        ...jarVariant?.node,
                        ...updatedJarVariant,
                    }

                    if (jarVariant?.node?.availableForSale) {
                        kitLineItem.price += Number(updatedJarVariant?.price?.amount) * product.quantity
                    }
                }
            }

            for (const genericLineItem of this.genericProductLineItemsArr) {
                const matchedLineItem = latestProductDetails.find((product) => product.id === genericLineItem.variantId)

                // update line item with latest details
                genericLineItem.variant = {
                    ...genericLineItem.variant,
                    ...matchedLineItem,
                }
            }
        }

        this._updateCartLocalStorage()
        this._updateCartKitLocalStorage()
        this._updateGenericProductCartLocalStorage()

        return
    }

    // Add an item to the Cart
    addLineItem(item: IMedicineLineItem): IMedicineLineItem[] {
        const [lineItem, lineItemIndex] = this.getLineItem(item.variantId)
        if (lineItem) {
            lineItem.quantity += item.quantity
            this.lineItemsArr[lineItemIndex] = lineItem
        } else {
            this.lineItemsArr.push(item)
        }
        this._updateCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.lineItems
    }

    // update the entire cart
    bulkUpdateCart(items: IMedicineLineItem[]): IMedicineLineItem[] {
        this.lineItemsArr = items
        return this.lineItems
    }

    // add quantity
    addQuantity(quantity: number, variantId: string): IMedicineLineItem[] {
        const [lineItem, lineItemIndex] = this.getLineItem(variantId)

        if (!lineItem) {
            console.error(`Cannot find LineItem with ${variantId} variantId`)
            return
        }

        lineItem.quantity += quantity
        this.lineItemsArr[lineItemIndex] = lineItem
        this._updateCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.lineItems
    }

    // Add an item to the Cart
    removeLineItem(variantId: string): IMedicineLineItem[] {
        const [, lineItemIndex] = this.getLineItem(variantId)
        if (lineItemIndex === -1) {
            return
        }

        this.lineItemsArr.splice(lineItemIndex, 1)
        this._updateCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.lineItems
    }

    // subtract quantity
    subtractQuantity(quantity: number, variantId: string): IMedicineLineItem[] {
        const [lineItem, lineItemIndex] = this.getLineItem(variantId)

        if (!lineItem) {
            console.error(`Cannot find LineItem with ${variantId} variantId`)
            return
        }

        lineItem.quantity -= quantity
        this.lineItemsArr[lineItemIndex] = lineItem
        this._updateCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.lineItems
    }

    _updateCartLocalStorage(): void {
        setItemInLS(CART_LS_KEY, JSON.stringify(this.lineItems))
    }

    /**
     * Replaces line item with another variant
     * @param toBeReplacedVariantId string
     * @param replaceByItem string
     * @returns ILineItem[]
     */
    replace(toBeReplacedVariantId: string, replaceByItem: IMedicineLineItem): IMedicineLineItem[] {
        const [, lineItemIndex] = this.getLineItem(toBeReplacedVariantId)
        this.lineItemsArr[lineItemIndex] = replaceByItem
        this._updateCartLocalStorage()
        return this.lineItems
    }

    // kit line items
    get kitLineItems(): IKitLineItem[] {
        // return cloned objects
        return this.kitLineItemsArr.map((lineItem) => ({ ...lineItem }))
    }

    // return particular line item
    getKitLineItem(kitId: string): [IKitLineItem, number] {
        let kitLineImteIndex = -1

        const kitLineItem = this.kitLineItemsArr.find((kitLineItem, index) => {
            if (kitLineItem.id === kitId) {
                kitLineImteIndex = index
                return true
            }
        })

        return [kitLineItem === undefined ? undefined : { ...kitLineItem }, kitLineImteIndex]
    }

    addKitLineItem(kit: IKitLineItem): IKitLineItem[] {
        this.kitLineItemsArr.push(kit)
        this._updateCartKitLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.kitLineItems
    }

    removeKitLineItem(kitId: string): IKitLineItem[] {
        const [, lineItemIndex] = this.getKitLineItem(kitId)
        if (lineItemIndex === -1) {
            return
        }

        this.kitLineItemsArr.splice(lineItemIndex, 1)
        this._updateCartKitLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.kitLineItems
    }

    addKitQuantity(quantity: number, kitId: string): IKitLineItem[] {
        const [kitLineItem, kitLineItemIndex] = this.getKitLineItem(kitId)

        if (!kitLineItem) {
            console.error(`Cannot find LineItem with ${kitId} variantId`)
            return
        }

        kitLineItem.quantity += quantity
        this.kitLineItemsArr[kitLineItemIndex] = kitLineItem
        this._updateCartKitLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.kitLineItems
    }

    subtractKitQuantity(quantity: number, kitId: string): IKitLineItem[] {
        const [kitLineItem, kitLineItemIndex] = this.getKitLineItem(kitId)

        if (!kitLineItem) {
            console.error(`Cannot find LineItem with ${kitId} variantId`)
            return
        }

        kitLineItem.quantity -= quantity
        this.kitLineItemsArr[kitLineItemIndex] = kitLineItem
        this._updateCartKitLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.kitLineItems
    }

    // update the entire cart
    bulkUpdateKit(items: IKitLineItem[]): IKitLineItem[] {
        this.kitLineItemsArr = items
        return this.kitLineItems
    }

    _updateCartKitLocalStorage(): void {
        setItemInLS(KIT_LS_KEY, JSON.stringify(this.kitLineItems))
    }

    _updateRemoteCheckoutLocalStorage(): void {
        setItemInLS(REMOTE_CHECKOUT_LS_KEY, JSON.stringify(this.remoteCheckout || '{}'))
    }

    /**
     * Sends current client side cart to Shopify's remote cart.
     * Once complete, creates a checkout from the cart.
     *
     *
     * This allows us to:
     *
     * 1. Track carts
     * 2. Set up a cart with a selling plan (subscription)
     * 3. Speed up the checkout process
     */
    async updateRemoteCheckout(): Promise<IShopifyCheckout> {
        // First create the remote cart
        await this.createCart()
        // Create checkout reads the created checkout
        // ToDo use the cart ID to create the checkout (needed for subscriptions)
        await this.createCheckout()

        this._updateRemoteCheckoutLocalStorage()
        return this.remoteCheckout
    }

    /**
     * Takes the client side cart and creates a cart in Shopify
     */
    async createCart(): Promise<IShopifyCart | null> {
        const remoteCart = await createRemoteCart(
            this.allCartItemsAsLineItem,
            this.currentUser,
            this.currentIdentity,
            this.coupon?.discountCode,
        )
        if (remoteCart.errors) {
            console.error(remoteCart.errors)
            this.remoteCart = null
        }
        this.remoteCart = remoteCart?.data?.cartCreate?.cart
        return this.remoteCart
    }

    /**
     * Takes the client side cart and creates a checkout in Shopify
     */
    async createCheckout(): Promise<IShopifyCheckout | null> {
        const remoteCheckout = await createRemoteCheckout(
            this.allCartItemsAsLineItem,
            this.currentUser,
            this.currentIdentity,
            this.coupon?.discountCode,
        )
        this.remoteCheckout = remoteCheckout
        return this.remoteCheckout
    }

    /**
     * Redirect the customer to checkout
     */
    async checkout(): Promise<void> {
        // create cart before checkout to make
        // sure the latest changes in cart is
        // used to create checkout
        await this.createCart()

        if (this.remoteCart === null) {
            console.error('Error checking out - no cart')
            return
        }

        // starts with '?'
        let utmQueryParams = '?'

        try {
            for (const key in sessionStorage) {
                if (key.startsWith('utm_')) {
                    const utmQuery = `${key}=${sessionStorage[key]}`
                    utmQueryParams += utmQuery + '&'
                }
            }
        } catch (e) {
            Sentry.captureException(e)
        }

        // remove trailing '&'
        // if no utm_ properties found in session storage
        // then this removes '?' making utmQueryParams
        // an empty string
        utmQueryParams = utmQueryParams.slice(0, -1)

        setItemInLS(CHECKOUT_TIMESTAMP_LS_KEY, Date.now().toString())

        // redirect to shopify checkout url with utmQueryParams if present
        const checkoutUrl = this.remoteCart.checkoutUrl + utmQueryParams
        window.location.href = checkoutUrl
    }

    _updateCartCouponLocalStorage(): void {
        setItemInLS(COUPON_LS_KEY, JSON.stringify(this.coupon ?? null))
    }

    setCoupon(coupon: ICoupon): void {
        this.coupon = coupon
        this._updateCartCouponLocalStorage()
    }

    getCoupon(): ICoupon {
        return this.coupon
    }

    async applyCoupon(): Promise<IShopifyCheckout> {
        if (!this.remoteCheckout?.id) {
            console.error('Checkout id not found')
            return null
        }

        const checkout = await applyCouponToCheckout(
            this.remoteCheckout.id,
            this.coupon ? this.coupon.discountCode : null,
        )

        return checkout
    }

    getCheckout(): IShopifyCheckout {
        return this.remoteCheckout
    }

    clearCart(): void {
        this.lineItemsArr = []
        this.kitLineItemsArr = []
        this.genericProductLineItemsArr = []

        this._updateCartLocalStorage()
        this._updateCartKitLocalStorage()
        this._updateGenericProductCartLocalStorage()
    }

    getGenericProductLineItem(variantId: string): [IGenericProductLineItem, number] {
        let genericProductLineItemIndex = -1

        const lineItem = this.genericProductLineItemsArr.find((lineItem, index) => {
            if (lineItem.variantId === variantId) {
                genericProductLineItemIndex = index
                return true
            }
        })

        return [lineItem === undefined ? undefined : { ...lineItem }, genericProductLineItemIndex]
    }

    addGenericProductLineItem(item: IGenericProductLineItem): IGenericProductLineItem[] {
        const [genericProductLineItem, genericProductLineItemIndex] = this.getGenericProductLineItem(item.variantId)
        if (genericProductLineItem) {
            genericProductLineItem.quantity += item.quantity
            this.genericProductLineItemsArr[genericProductLineItemIndex] = genericProductLineItem
        } else {
            this.genericProductLineItemsArr.push(item)
        }
        this._updateGenericProductCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.genericProductLineItems
    }

    _updateGenericProductCartLocalStorage(): void {
        setItemInLS(CART_GENERIC_PRODUCT_LS_KEY, JSON.stringify(this.genericProductLineItemsArr))
    }

    // add generic product quantity
    addGenericProductQuantity(quantity: number, variantId: string): IGenericProductLineItem[] {
        const [genericProductLineItem, genericProductLineItemIndex] = this.getGenericProductLineItem(variantId)

        if (!genericProductLineItem) {
            console.error(`Cannot find LineItem with ${variantId} variantId`)
            return
        }

        genericProductLineItem.quantity += quantity
        this.genericProductLineItemsArr[genericProductLineItemIndex] = genericProductLineItem
        this._updateGenericProductCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.genericProductLineItems
    }

    // Add an item to the Cart
    removeGenericProductLineItem(variantId: string): IGenericProductLineItem[] {
        const [, genericProductLineItemIndex] = this.getGenericProductLineItem(variantId)
        if (genericProductLineItemIndex === -1) {
            return
        }

        this.genericProductLineItemsArr.splice(genericProductLineItemIndex, 1)
        this._updateGenericProductCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.genericProductLineItems
    }

    // subtract quantity
    subtractGenericProductQuantity(quantity: number, variantId: string): IGenericProductLineItem[] {
        const [genericProductLineItem, genericProductLineItemIndex] = this.getGenericProductLineItem(variantId)

        if (!genericProductLineItem) {
            console.error(`Cannot find LineItem with ${variantId} variantId`)
            return
        }

        genericProductLineItem.quantity -= quantity
        this.genericProductLineItemsArr[genericProductLineItemIndex] = genericProductLineItem
        this._updateGenericProductCartLocalStorage()

        // invalidate last checked out date
        removeItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY)

        return this.genericProductLineItems
    }

    // Convert line items to an array and return
    get genericProductLineItems(): IGenericProductLineItem[] {
        // return cloned objects
        return this.genericProductLineItemsArr.map((lineItem) => ({ ...lineItem }))
    }

    bulkUpdateGenericProductsCart(items: IGenericProductLineItem[]): IGenericProductLineItem[] {
        this.genericProductLineItemsArr = items
        return this.genericProductLineItems
    }
}
