import * as React from 'react'
import { ILineItemStripe } from 'interfaces/order'
import { CartPrescriptionLineItem } from './cart-prescription-line-item'
import { CartPackageLineItem } from './cart-package-line-item'
import { PRODUCT_TYPE } from 'enum/product-type'
import { CartOTCLineItem } from './cart-otc-line-item'
import { CartMembershipLineItem } from './cart-membership-line-item'
import { IPrescriptionLineItemProps } from './cart-prescription-line-item'
import { IProps as IMembershipLineItemProps } from './cart-membership-line-item'

interface IProps {
    lineItem: ILineItemStripe
    onRemove?: IPrescriptionLineItemProps['onRemove']
    isRemovable?: boolean
    onEdit?: IMembershipLineItemProps['onEdit']
    showCompareLabel?: boolean
}

export function CartLineItem({ lineItem, isRemovable, onRemove, onEdit, showCompareLabel }: IProps) {
    switch (lineItem.type) {
        case PRODUCT_TYPE.PRESCRIPTION: {
            return (
                <CartPrescriptionLineItem
                    lineItem={lineItem}
                    onRemove={onRemove}
                    isRemovable={isRemovable}
                    showCompareLabel={showCompareLabel}
                />
            )
        }
        case PRODUCT_TYPE.PACKAGE:
            return <CartPackageLineItem lineItem={lineItem} />
        case PRODUCT_TYPE.OTC:
            return <CartOTCLineItem lineItem={lineItem} />
        case PRODUCT_TYPE.MEMBERSHIP:
            return <CartMembershipLineItem lineItem={lineItem} onEdit={onEdit} />
        default:
            return null
    }
}
