import * as React from 'react'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { ILineItemOTC, ILineItemPackage } from 'interfaces/order'
import { PRODUCT_TYPE } from 'enum/product-type'
import globalManifest from 'data/global-manifest.json'

interface IProps {
    lineItem: ILineItemPackage | ILineItemOTC
}

const { resources: globalUIResources } = globalManifest

export function CartPackageLineItem({ lineItem }: IProps): React.ReactElement {
    const { product_name, quantity, total_price, type } = lineItem

    const totalPriceNum = Number(total_price)

    return (
        <div
            data-cy={type === PRODUCT_TYPE.PACKAGE ? 'package-line-item' : 'otc-line-item'}
            className="bg-white rounded-2.5xl px-5 py-3.5"
        >
            <div className="flex flex-wrap xs:flex-nowrap justify-between items-start gap-2">
                <div className="flex w-full">
                    {/* product details */}
                    <div className="w-full flex justify-between items-start">
                        <div className="w-0 grow flex gap-x-0.5 items-center">
                            <span className="text-sm font-bold block truncate">{product_name}</span>
                            <span className="text-gray text-xs ml-1">
                                ({globalUIResources['quantityShortLabel'].value} {quantity})
                            </span>
                            {/* price */}
                            <div
                                className={`text-xsm font-bold ml-auto ${totalPriceNum === 0 ? '' : 'tracking-wider'}`}
                            >
                                {totalPriceNum === 0
                                    ? globalUIResources['includedLabel'].value
                                    : getFormattedNumberWithCurrency(totalPriceNum, undefined)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
