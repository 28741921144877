// Experiment feature flag names and related variables
export const blockOtcProductsFeatuerFlagName = 'block-otc-products'
export const rxFlowProviderInfoFeatureFlagName = 'rx-flow-provider-info'
export const rxFlowStepsFeatureFlagName = 'rx-flow-steps'
export const rxFlowInformationalVideoFeatureFlagName = 'rx-flow-informational-video'
export const customizerProductCardsExperimentName = 'customizer-product-cards'
export const priceElasticityExperimentName = 'price-elasticity-experiment'
export const priceElasticityExperimentControlVariationName = 'price-variation-control'
export const accountPortalHeroExperimentName = 'account-portal-hero'
export const websiteSubscribeOrderExperimentName = 'website-subscribe-sms-email-order'
export const websiteSubscribeOrderEmailFirstVariation = 'email-first'
export const websiteSubscribeOrderPhoneFirstVariation = 'phone-first'
export const websiteSubscribeFullScreenExperimentName = 'website-subscribe-full-screen'
export const disableBundleCustomizationExperimentName = 'disable-bundle-customization'
export const showRxPDPFormSubmitButtonExperimentName = 'show-rx-pdp-form-submit-button'
export const showDetailedPersonalizedCapExperimentName = 'show-detailed-personalized-cap'
export const shippingThresholdExperimentName = 'shipping-threshold-land-exp'
export const rxHomeHeroExperimentName = 'rx-home-hero'
export const rxSignUpLineItemsFeatureName = 'rx-sign-up-line-items'
export const rxFlowHideSubtotalFeatureName = 'rx-flow-hide-subtotal'
export const rxFlowHideSubtotalExperimentTestVariationName = 'rx-flow-hide-subtotal-test'
export const plasticCalculatorCTAFeatureName = 'plastic-calculator-cta'
export const hideBlogPostPopupFeatureName = 'hide-popup-on-blog-posts'
export const rxHeroCopyTestFeatureName = 'rx-hero-copy'
export const hideEmailCapturePopupFeatureName = 'hide-email-capture-popup'
export const membershipPriceDiscountFeatureName = 'membership-price-discount'

// feature flag variations/values
export const rxFlowInformationalVideoExperimentTestVariationName = 'rx-flow-informational-video-test'
export const shippingThresholdExperimentVariationOne = 'FREESHIP30'
export const shippingThresholdExperimentVariationTwo = 'FREESHIP40'
