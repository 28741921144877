import { gql } from '@apollo/client/core/index'
import { IMedicine } from 'interfaces/medicine'
import { createApolloClient } from '../apollo-client'

const client = createApolloClient()

/**
 * Returns the total price of product
 * Base price of jar variants includes price of jar + price of one pouch
 * @param basePrice number
 * @param pricePerPouch number
 * @param quantity number
 * @returns number
 */
export function getTotalPriceOfProduct(basePrice: number, pricePerPouch: number, quantity: number): number {
    if (!quantity) {
        return 0
    }
    // 1/25/22 - changed logic such that multiple starter sets == multiple starter sets
    // Previously, our logic was 1 set + additional pouches.
    return basePrice * quantity
}

/**
 * Converts a base64 encoded Shopify gid into it's string components
 * @param gid string
 * @returns object
 */
interface IGidDetails {
    type: string
    id: string
    _raw: string
}
export function gidToDetails(gid?: string): IGidDetails {
    const details: IGidDetails = { type: '', id: '', _raw: '' }
    details._raw = gid
    // remove everything after '?' including itself, in cases id attaches ?key= at the end
    const components = gid?.replace(/\?.*$/, '')?.split('/')
    if (components.length) {
        if (components[4]) details.id = components[4]
        if (components[3]) details.type = components[3]
    }
    return details
}

export const MEDICINE_CATEGORY_FROM_PRODUCT_SKU_IDENTIFIERS_QUERY = gql`
    query MedicineCategoryFromProductIds($medicineSKUIdentifiers: [String!]!) {
        medicineCollection(where: { medicineSKUIdentifier_in: $medicineSKUIdentifiers }) {
            items {
                cabinetDrugName
                medicineSkuIdentifier
                pillType
                pillTypePlural
                frontPouchImage {
                    url
                    height
                    width
                    title
                    description
                }
                jarWithMedicineImage {
                    url
                    height
                    width
                    title
                    description
                }
                medicineCategory {
                    name
                }
                primaryProductPage {
                    accountPortalImage {
                        url
                        height
                        width
                        title
                        description
                    }
                    slug
                    productName
                    publishPage
                    targetCollectionUrl
                    amazonCollectionUrl
                    targetStarterSetUrl
                    targetUrlRefill
                    amazonStarterSetUrl
                    amazonUrlRefill
                }
            }
        }
    }
`

export const MEDICINE_PRODUCT_PAGE_QUERY = gql`
    query MedicineProductPage($medicineSKUIdentifiers: [String!]!) {
        medicineCollection(where: { medicineSKUIdentifier_in: $medicineSKUIdentifiers }) {
            items {
                __typename
            }
        }
    }
`

export const GENERIC_PRODUCT_PARENT_PAGE_QUERY = gql`
    query GenericProductPage($productSKUIdentifiers: [String!]!) {
        productPageGenericCollection(where: { productSKUIdentifiers_contains_some: $productSKUIdentifiers }) {
            items {
                productSkuIdentifiers
                linkedFrom {
                    productPageCollection(limit: 1) {
                        items {
                            productName
                        }
                    }
                }
                variantMediaCollection {
                    items {
                        title
                        associatedColors
                    }
                }
            }
        }
    }
`

export async function getMedicineCategoryFromProductIds(
    medicineNumberFromSKUIdentifier: string[],
): Promise<IMedicine[]> {
    const data = await client.query({
        query: MEDICINE_CATEGORY_FROM_PRODUCT_SKU_IDENTIFIERS_QUERY,
        variables: {
            medicineSKUIdentifiers: medicineNumberFromSKUIdentifier,
        },
    })

    return data.data.medicineCollection.items
}
