import { useOTCCartContext } from 'context/otc-cart-context'
import { IGenericProductLineItem, IKitLineItem, IMedicineLineItem, IShopifyCheckout } from 'lib/cart'

interface ICartDiscount {
    amount: number
    currencyCode: string
    percentage?: string
}

interface ICartCalculations {
    subTotal: number
    discounts: Record<string, ICartDiscount>
    total: number
    couponDiscount: number
}

interface IGetCartCalculationsArguments {
    cartLineItems: IMedicineLineItem[]
    kitLineItems: IKitLineItem[]
    remoteCheckout: IShopifyCheckout
    genericProductLineItems: IGenericProductLineItem[]
}

const useOTCCartCalculation = (): ICartCalculations => {
    const { cartLineItems, kitLineItems, remoteCheckout, genericProductLineItems } = useOTCCartContext()
    const calculations = getCartCalculations({
        cartLineItems,
        kitLineItems,
        remoteCheckout,
        genericProductLineItems,
    })
    return calculations
}

// Compute the cart value
export const getCartCalculations = ({ remoteCheckout }: IGetCartCalculationsArguments): ICartCalculations => {
    const discounts = {}

    // we're only applying a single coupont at once
    // so we can collect this value from a discount code application
    let couponDiscount = 0

    remoteCheckout?.lineItems?.edges.forEach((edge) => {
        // Loop through each line item's discount allocations
        edge.node.discountAllocations.forEach((discountAllocation) => {
            const application = discountAllocation.discountApplication

            const discountAmount = Number(discountAllocation.allocatedAmount?.amount ?? 0)

            // No title means this application no longer applies or it's a discount code which doesnt have a title
            if (!application.title && !application.code) return

            if (application.code) {
                couponDiscount += discountAmount
            } else if (application.title) {
                if (discounts[application.title]) {
                    discounts[application.title].amount += discountAmount
                } else {
                    discounts[application.title] = {
                        amount: discountAmount,
                        currencyCode: application.value.currencyCode,
                    }
                }
            }

            if (application.title === 'Bundle discount') {
                // discounts[application.title].percentage = bundleDiscount?.discount_value_perc * 100
                discounts[application.title].percentage = undefined // temp solution to support 6 or more 20% discount
            }
        })
    })

    const subTotal = Number(remoteCheckout?.lineItemsSubtotalPrice?.amount)
    const total = Number(remoteCheckout?.totalPrice?.amount)

    return {
        subTotal,
        discounts,
        total,
        couponDiscount,
    }
}

export default useOTCCartCalculation
