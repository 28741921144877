import { useMutation, useQuery } from '@apollo/client'
import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from 'components/auth/auth'
import { useCustomerContext, NEW_CUSTOMER_KEY } from 'context/customer-context'
import { CLIENT_SIDE_USER_GRAPHQL_QUERY, GET_SHOPIFY_ACCESS_TOKEN } from 'lib/api/users'
import { cabinetJWTNamespace, multipassClaimsKey } from 'components/auth/auth'
import { IUser } from 'interfaces/user'

interface IUserContext {
    user: IUser
    loading: boolean
}

/**
 * To note, our Auth context also has a "user" property; that is the user returned from the auth flow
 *
 * The UserContext, as defined here, is the source of truth for all non-auth0 related properties of a user
 * as defined in our database.
 */
const UserContext = createContext<IUserContext>(null)
interface IProps {
    children: React.ReactNode
}

const UserContextProvider = ({ children }: IProps): React.ReactElement => {
    const { user: authUser, isAuthenticated, loading: authLoading } = useAuth()
    const customer = useCustomerContext()
    const { query } = useRouter()

    const { data: userData, loading: loadingUser } = useQuery(CLIENT_SIDE_USER_GRAPHQL_QUERY, {
        skip: !isAuthenticated,
    })

    const user = userData?.user?.[0]

    // Identify customer if email passed in as a query param
    useEffect(() => {
        if (query.email) {
            const email = Array.isArray(query.email) ? query.email[0] : query.email
            customer.identify(email)
        }
    }, [query, customer])

    // Generated a customer accessToken
    const [requestAccessToken, { data: accessToken }] = useMutation(GET_SHOPIFY_ACCESS_TOKEN)
    useEffect(() => {
        if (authUser && authUser[cabinetJWTNamespace] && authUser[cabinetJWTNamespace][multipassClaimsKey]) {
            requestAccessToken({
                variables: {
                    multipassToken: authUser[cabinetJWTNamespace][multipassClaimsKey],
                },
            })
        }
    }, [authUser, requestAccessToken])

    // If we know auth has finished loading, identify user in heap
    useEffect(() => {
        if (customer && !authLoading && !customer.loading && isAuthenticated && authUser?.email) {
            customer.identify(authUser.email)
            // Assuming if they have an account they're a returning customer
            customer.clearEventProperties()
            customer.addEventProperties({ [NEW_CUSTOMER_KEY]: false })
        }
    }, [customer, customer?.loading, authLoading, authUser, isAuthenticated])

    const customerAccessToken = accessToken?.customerAccessTokenCreateWithMultipass?.customerAccessToken

    const userValue = useMemo(() => {
        const updatedUser = {
            ...user, // data from our db
            shopifyCustomerAccessToken: customerAccessToken,
        }
        return { user: updatedUser, loading: authLoading || loadingUser }
    }, [customerAccessToken, user, authLoading, loadingUser])

    return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
}

export const useUserContext = (): IUserContext => {
    const accountContext = useContext(UserContext)

    if (typeof UserContext === 'undefined') {
        throw new Error('You cannot use UserContext outside UserContextProvider')
    }

    return accountContext
}

export default UserContextProvider
