import * as React from 'react'
import {
    CART_GENERIC_PRODUCT_LS_KEY,
    CART_LS_KEY,
    COUPON_LS_KEY,
    IGenericProductLineItem,
    IKitLineItem,
    IMedicineLineItem,
    KIT_LS_KEY,
    REMOTE_CHECKOUT_LS_KEY,
} from 'lib/cart'
import { ICoupon } from 'interfaces/coupon'
import {
    CART_GENERIC_LINE_ITEM_MODIFIED_LS_KEY,
    setCanUpdateQuantityPropertyForRecyclingBagLineItem,
    cartGenericLineItemModifiedToShopify_V_2022_10Identifier,
    migrateCartGenericLineItemsToUseShopifyV_2022_10,
    clearCart,
    CART_V2_CLEARED_LS_KEY,
} from 'lib/util/cart'
import * as Sentry from '@sentry/nextjs'
import { getItemFromLS, setItemInLS } from 'lib/util/storage'

interface ILocalStorage {
    cartItems: IMedicineLineItem[]
    cartKitItems: IKitLineItem[]
    remoteCheckout: any
    coupon: ICoupon
    genericProductLineItems: IGenericProductLineItem[]
}

const useLocalStorage = (): ILocalStorage => {
    React.useEffect(() => {
        const cartV2Cleared = getItemFromLS(CART_V2_CLEARED_LS_KEY)
        if (!cartV2Cleared) {
            clearCart()
            setItemInLS(CART_V2_CLEARED_LS_KEY, 'true')
        }
    }, [])

    const [cartItems, setCartItems] = React.useState<IMedicineLineItem[]>([])
    const [cartKitItems, setCartKitItems] = React.useState<IKitLineItem[]>([])

    const [remoteCheckout, setRemoteCheckout] = React.useState(() => {
        // load form localStorage in the initial render
        // wrapped inside a function so that we don't
        // acess localStorage in every render

        if (typeof window === 'undefined') {
            return null
        }

        const remoteCheckout = getItemFromLS(REMOTE_CHECKOUT_LS_KEY)
        return JSON.parse(remoteCheckout || '{}')
    })

    const [coupon, setCoupon] = React.useState<ICoupon>(() => {
        let couponInLS = null
        try {
            couponInLS = JSON.parse(getItemFromLS(COUPON_LS_KEY) ?? null)
        } catch {}

        return couponInLS
    })

    const [genericProductLineItems, setGenericProductLineItems] = React.useState<IGenericProductLineItem[]>(() => {
        // load form localStorage in the initial render
        // wrapped inside a function so that we don't
        // acess localStorage in every render

        if (typeof window === 'undefined') {
            return []
        }

        // local stroage cleanup
        try {
            const genericProductLineItemModified = JSON.parse(
                getItemFromLS(CART_GENERIC_LINE_ITEM_MODIFIED_LS_KEY) ?? null,
            )

            switch (genericProductLineItemModified) {
                case null:
                    setCanUpdateQuantityPropertyForRecyclingBagLineItem()
                case cartGenericLineItemModifiedToShopify_V_2022_10Identifier - 1:
                    migrateCartGenericLineItemsToUseShopifyV_2022_10()
            }
        } catch (e) {
            Sentry.captureException(e)
        }

        return []
    })

    React.useEffect(() => {
        // setup storage event listener
        const storageListener = ({ key, newValue }) => {
            if (key === CART_LS_KEY) {
                setCartItems(JSON.parse(newValue || '[]'))
            }

            if (key === KIT_LS_KEY) {
                setCartKitItems(JSON.parse(newValue || '[]'))
            }

            if (key === REMOTE_CHECKOUT_LS_KEY) {
                setRemoteCheckout(newValue ? JSON.parse(newValue) : {})
            }

            if (key === COUPON_LS_KEY) {
                setCoupon(JSON.parse(newValue) ?? null)
            }

            if (key === CART_GENERIC_PRODUCT_LS_KEY) {
                setGenericProductLineItems(JSON.parse(newValue || '[]'))
            }
        }

        window.addEventListener('storage', storageListener)

        return () => {
            window.removeEventListener('storage', storageListener)
        }
    }, [])

    return { cartItems, cartKitItems, remoteCheckout, coupon, genericProductLineItems }
}

export default useLocalStorage
