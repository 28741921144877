import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client/core/index'
import { RetryLink } from '@apollo/client/link/retry/index.js'
import { onError } from '@apollo/client/link/error/index.js'
import * as Sentry from '@sentry/nextjs'
import nodeFetch from 'node-fetch'

interface IHeaders extends Record<string, string> {
    Authorization?: string
    'x-hasura-role': 'user' | 'anonymous'
}

// operations to retry
//
const retryOperations = [
    'ShopifyProduct',
    'ShopifyProducts',
    'createCheckoutMutation',
    'createCartMutation',
    'CurrentPricesAndInventory',
]

let unauthorizedClient: InstanceType<typeof ApolloClient> = null

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if (message === 'Throttled') {
                return forward(operation)
            }

            if (typeof window !== 'undefined') {
                Sentry.captureException(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation: ${operation.operationName}`,
                )
            } else {
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation: ${operation.operationName}`,
                )
            }
        })
    if (networkError) {
        if (typeof window !== 'undefined') {
            Sentry.captureException(`[Network error]: ${networkError}, Operation: ${operation.operationName}`)
        } else {
            console.error(`[Network error]: ${networkError}, Operation: ${operation.operationName}`)
        }
    }
})

const retryLink = new RetryLink({
    attempts: {
        max: 3,
        retryIf: (error, operation) => !!error && retryOperations.includes(operation.operationName),
    },
    delay: {
        initial: 300, // 300ms
        max: 8000, // wait for at most 8 seconds to retry
    },
})

export function createApolloClient(authToken?: string): InstanceType<typeof ApolloClient> {
    if (authToken) {
        return createAuthorizedApolloClient(authToken)
    } else {
        return createUnauthorizedApolloClient()
    }
}

export const createAuthorizedApolloClient = (authToken: string): InstanceType<typeof ApolloClient> => {
    const headers: IHeaders = {
        Authorization: `Bearer ${authToken}`,
        'x-hasura-role': 'user',
    }

    const httpLink = new HttpLink({
        uri: `${process.env.HASURA_GRAPHQL_ENDPOINT}/v1/graphql`,
        headers,
        fetch: typeof window === 'undefined' ? nodeFetch : fetch,
    })

    return new ApolloClient({
        link: from([errorLink, retryLink, httpLink]),
        cache: new InMemoryCache(),
    })
}

export const createUnauthorizedApolloClient = (): InstanceType<typeof ApolloClient> => {
    if (unauthorizedClient) {
        return unauthorizedClient
    }

    const headers: IHeaders = {
        'x-hasura-role': 'anonymous',
    }

    const httpLink = new HttpLink({
        uri: `${process.env.HASURA_GRAPHQL_ENDPOINT}/v1/graphql`,
        headers,
        fetch: typeof window === 'undefined' ? nodeFetch : fetch,
    })

    unauthorizedClient = new ApolloClient({
        link: from([errorLink, retryLink, httpLink]),
        cache: new InMemoryCache(),
    })

    return unauthorizedClient
}
