import { gql } from '@apollo/client'
import { IUser } from 'interfaces/user'
import { createApolloClient } from 'lib/apollo-client'
import { IAllLineItems, ILineItem, IShopifyCheckout } from 'lib/cart'

const client = createApolloClient()

interface IResponse {
    data?: any
    errors?: unknown
}

const emptyCheckout: IShopifyCheckout = {
    id: '',
    webUrl: undefined,
    lineItems: {
        edges: [],
    },
    totalPrice: {
        amount: '0.0',
        currencyCode: 'USD',
        __typename: 'MoneyV2',
    },
    lineItemsSubtotalPrice: {
        amount: '0.0',
        currencyCode: 'USD',
        __typename: 'MoneyV2',
    },
    discountApplications: {
        __typename: 'DiscountApplicationConnection',
        edges: [],
    },
}

type ICartResponse = IResponse

interface ICartLine {
    merchandiseId?: string
    variantId?: string
    quantity: number
    sellingPlanId?: string
}

interface ICartBuyerIdentityInput {
    countryCode?: string
    customerAccessToken?: string
    email?: string
    phone?: string
}

// Define the data we want for checkout line items
// Note, this is where we get discount allocations to show in the cart
const lineItemsQuery = `
    lineItems(first: 100) {
        edges {
            node {
                id
                quantity
                variant {
                    id
                    price {
                        amount
                        currencyCode
                    }
                }
                discountAllocations {
                    allocatedAmount {
                        amount
                    }
                    discountApplication {
                        ... on ScriptDiscountApplication {
                            __typename
                            title
                            value {
                                ... on MoneyV2 {
                                    __typename
                                    amount
                                    currencyCode
                                }
                                ... on PricingPercentageValue {
                                    __typename
                                    percentage
                                }
                            }
                        }
                        ... on DiscountCodeApplication {
                            __typename
                            code
                            value {
                                ... on MoneyV2 {
                                    __typename
                                        amount
                                        currencyCode
                                }
                                ... on PricingPercentageValue {
                                    __typename
                                    percentage
                                }
                            }
                          }
                    }
                }
            }
        }
    }
    `

const discountApplicationQuery = `
discountApplications(first: 1, reverse: true) {
    edges {
        node {
            ... on DiscountCodeApplication {
                __typename
                code
                value {
                    ... on PricingPercentageValue {
                        __typename
                        percentage
                    }
                }
            }
        }
    }
}
`

const checkoutPriceQuery = `
    totalPrice {
        amount
        currencyCode
    }
    lineItemsSubtotalPrice {
        amount
        currencyCode
    }
`

// Depneding if this is a createCheckout or updateCheckout (customerAssociate), extract the checkout object
const extractCheckout = (data) => {
    if (data?.data?.checkoutCreate) {
        return data.data.checkoutCreate.checkout
    } else if (data?.data?.checkoutCustomerAssociateV2) {
        return data.data.checkoutCustomerAssociateV2?.checkout
    } else if (data?.data?.checkoutLineItemsUpdate) {
        return data.data.checkoutLineItemsUpdate.checkout
    } else {
        return null
    }
}

export const createRemoteCart = async (
    lineItems: IAllLineItems,
    currentUser?: IUser,
    currentIdentity?: string,
    discountCode?: string,
): Promise<ICartResponse> => {
    const organizedCartLines = organizeLineItemsForCheckout(lineItems)
    if (organizedCartLines.length === 0) return {}
    // remap variantId to merchandiseId
    const lines = organizedCartLines.map((item) => {
        const line: ICartLine = {
            merchandiseId: item.merchandiseId,
            quantity: item.quantity,
        }
        if (item?.sellingPlanId) {
            line.sellingPlanId = item.sellingPlanId
        }
        return line
    })
    const mutation = gql`
        mutation createCartMutation($cartInput: CartInput!) {
            cartCreate(input: $cartInput) {
                cart {
                    id
                    checkoutUrl
                }
            }
        }
    `
    let buyerIdentity: ICartBuyerIdentityInput | undefined = undefined
    if (currentUser?.shopifyCustomerAccessToken?.accessToken) {
        buyerIdentity = {
            customerAccessToken: currentUser.shopifyCustomerAccessToken.accessToken,
        }
    }

    // We don't always have email, but if we do
    if (currentIdentity) {
        if (typeof buyerIdentity === 'undefined') {
            buyerIdentity = {
                email: currentIdentity,
            }
        } else {
            buyerIdentity.email = currentIdentity
        }
    }

    const data = await client.mutate({
        mutation,
        variables: { cartInput: { lines, buyerIdentity, discountCodes: discountCode } },
    })

    if (data.errors) {
        throw data.errors
    }
    return data
}

/**
 * If we know about a user, associate it to a checkout
 */
export const associateCustomerToRemoteCheckout = async (
    checkout: IShopifyCheckout,
    currentUser: IUser,
): Promise<IShopifyCheckout> => {
    let updatedCheckout = { ...checkout }
    const checkoutCustomerAssociateV2Mutation = gql`
            mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
                checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
                    checkout {
                        id
                        webUrl
                        ${lineItemsQuery}
                        ${checkoutPriceQuery}
                    }
                    customer {
                        id
                        tags
                    }
                    checkoutUserErrors {
                        code
                        field
                        message
                    }
                }
            }
        `

    // Execute the mutation to associate the customer
    const checkoutCustomerAssociateData = await client.mutate({
        mutation: checkoutCustomerAssociateV2Mutation,
        variables: {
            checkoutId: checkout.id,
            customerAccessToken: currentUser.shopifyCustomerAccessToken.accessToken,
        },
    })

    // Check for errors with the association
    if (
        !checkoutCustomerAssociateData?.errors &&
        checkoutCustomerAssociateData.data?.checkoutCustomerAssociateV2?.checkoutUserErrors?.length === 0
    ) {
        // Overwrite the checkout with the new checkout data
        updatedCheckout = extractCheckout(checkoutCustomerAssociateData)
    } else {
        console.error(checkoutCustomerAssociateData?.errors)
        console.error(checkoutCustomerAssociateData?.data?.checkoutCustomerAssociateV2?.checkoutUserErrors)
    }

    return updatedCheckout
}

// Update the email associated with a checkout.
export const updateCheckoutEmail = async (checkout: IShopifyCheckout, email: string): Promise<void> => {
    const checkoutUpdateEmailMutation = gql`
        mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
            checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
                checkout {
                    id
                    webUrl
                }
                checkoutUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `

    const updatedCheckout = await client.mutate({
        mutation: checkoutUpdateEmailMutation,
        variables: {
            checkoutId: checkout.id,
            email,
        },
    })

    // Check for errors on the line items update
    if (updatedCheckout?.errors || updatedCheckout.data?.checkoutEmailUpdateV2?.checkoutUserErrors?.length > 0) {
        console.error(updatedCheckout?.errors)
        console.error(updatedCheckout?.data?.checkoutEmailUpdateV2?.checkoutUserErrors)
    }
}

export const updateCheckoutLineItems = async (checkout: IShopifyCheckout): Promise<IShopifyCheckout> => {
    let updatedCheckout = { ...checkout }
    const checkoutUpdateLineItemsMutation = gql`
            mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
                checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
                    checkout {
                        id
                        webUrl
                        ${lineItemsQuery}
                        ${discountApplicationQuery}
                        ${checkoutPriceQuery}
                    }
                    checkoutUserErrors {
                        code
                        field
                        message
                    }
                }
            }
        `

    const lineItemsToUpdate = checkout?.lineItems?.edges?.map((edge) => {
        return {
            id: edge.node.id,
            quantity: edge.node.quantity,
        }
    })
    // Force an update so we run through our custom scripts
    const checkoutUpdateLineItemsData = await client.mutate({
        mutation: checkoutUpdateLineItemsMutation,
        variables: {
            checkoutId: checkout.id,
            lineItems: lineItemsToUpdate,
        },
    })

    // Check for errors on the line items update
    if (
        !checkoutUpdateLineItemsData?.errors &&
        checkoutUpdateLineItemsData.data?.checkoutLineItemsUpdate?.checkoutUserErrors?.length === 0
    ) {
        // Overwrite the checkout with the new checkout data (which potentially includes new discounts)
        updatedCheckout = extractCheckout(checkoutUpdateLineItemsData)
    } else {
        console.error(checkoutUpdateLineItemsData?.errors)
        console.error(checkoutUpdateLineItemsData?.data?.checkoutLineItemsUpdate?.checkoutUserErrors)
    }

    return updatedCheckout
}

/**
 * Create a Shopify Checkout from lineItems in our cart
 *
 * Note -- this is used client side and thus uses the anonymous Apollo client!
 */
export const createRemoteCheckout = async (
    lineItems: IAllLineItems,
    currentUser?: IUser,
    currentIdentity?: string,
    discountCode?: string,
): Promise<IShopifyCheckout> => {
    // Variable we'll ultimately return
    let checkout

    const organizedCartLines = organizeLineItemsForCheckout(lineItems, false, false).map((lineItem) => ({
        variantId: lineItem.variantId,
        quantity: lineItem.quantity,
    }))

    if (organizedCartLines.length === 0) return emptyCheckout

    // 1. First, create the checkout
    // Mutation defintion to create the intial query
    const createCheckoutMutation = gql`
        mutation createCheckoutMutation($checkoutInput: CheckoutCreateInput!) {
            checkoutCreate(input: $checkoutInput) {
                checkout {
                    id
                    webUrl
                    ${lineItemsQuery}
                    ${checkoutPriceQuery}
                }
            }
        }
    `

    const createData = await client.mutate({
        mutation: createCheckoutMutation,
        variables: { checkoutInput: { lineItems: organizedCartLines, email: currentIdentity } },
    })

    // If no errors, assign this data to the return
    if (!createData.errors) {
        checkout = extractCheckout(createData)
    } else {
        checkout = null
        console.error(createData.errors)
    }

    // If we have a user, fetch their discounts
    if (currentUser?.shopifyCustomerAccessToken?.accessToken && checkout?.id) {
        // If we have a logged in customer w/ an accessToken, associate them to the checkout so we can show their discounts
        checkout = await associateCustomerToRemoteCheckout(checkout, currentUser)
    }
    // if we have a discount code, apply it
    if (discountCode) {
        checkout = await applyCouponToCheckout(checkout.id, discountCode)
    }
    // One more query to update the line items so it catches the customer membership discounts + discount codes
    if (checkout?.id) {
        checkout = await updateCheckoutLineItems(checkout)
    }

    return checkout
}

/**
 * This function filters unavailable line items
 * and formats them to be compatible with shopify APIs
 * @param lineItems ILineItem[]
 * @returns ILineItem[]
 */
export const organizeLineItemsForCheckout = (
    lineItems: ILineItem[],
    testing = false,
    includeSellingPlanItems = true,
): ICartLine[] => {
    const allAvailableItems = lineItems.filter((lineItem) => lineItem.available)

    const SELLING_PLAN_ID_KEY = 'sellingPlanId'

    // todo: update test to remove this line
    if (testing) return allAvailableItems

    // Map items to Shopify format
    const allItemsShopifyFormat = allAvailableItems
        .filter((lineItem) => {
            // Remove lineItems with selling plans
            // @ts-ignore
            return !(!includeSellingPlanItems && lineItem?.sellingPlan)
        })
        .map((lineItem) => ({
            variantId: lineItem.variantId,
            quantity: lineItem.quantity,
            merchandiseId: lineItem.variantId,
        }))

    // merge duplicate line items and update quantities
    const mergedAllItemsObj: Record<string, ICartLine> = {}
    allItemsShopifyFormat.forEach((lineItem) => {
        if (!lineItem.hasOwnProperty(SELLING_PLAN_ID_KEY)) {
            if (!mergedAllItemsObj.hasOwnProperty(lineItem.variantId)) {
                mergedAllItemsObj[lineItem.variantId] = lineItem
            } else {
                mergedAllItemsObj[lineItem.variantId].quantity += lineItem.quantity
            }
        } else {
            // If it's an item with a selling plan, separate those out from potentially similar variants with no selling plan
            const variantId_SellingPlanId = `${lineItem.variantId}-${lineItem[SELLING_PLAN_ID_KEY]}`
            if (!mergedAllItemsObj.hasOwnProperty(variantId_SellingPlanId)) {
                mergedAllItemsObj[variantId_SellingPlanId] = lineItem
            } else {
                mergedAllItemsObj[variantId_SellingPlanId].quantity += lineItem.quantity
            }
        }
    })

    const mergedAllItems = Object.values(mergedAllItemsObj)

    return mergedAllItems
}

/**
 * Applies coupon/discount code to checkout
 * @param checkoutId ID
 * @param couponCode string
 * @returns discountApplications
 */
export const applyCouponToCheckout = async (checkoutId: string, couponCode: string): Promise<IShopifyCheckout> => {
    if (!checkoutId) {
        return null
    }

    const checkoutDiscountCodeApplyV2Mutation = gql`
        mutation checkoutDiscountCodeApplyV2Mutation($checkoutId: ID!, $discountCode: String!) {
            checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
                checkout {
                    webUrl
                    # reversing the edges makes the edge with
                    # discount percentage the first edge
                    ${lineItemsQuery}
                    ${discountApplicationQuery}
                    ${checkoutPriceQuery}
                }
            }
        }
    `

    const checkoutWithDiscount = await client.mutate({
        mutation: checkoutDiscountCodeApplyV2Mutation,
        variables: {
            checkoutId,
            discountCode: couponCode ?? '',
        },
    })

    return checkoutWithDiscount?.data?.checkoutDiscountCodeApplyV2?.checkout
}
