export const decodeBs64 = (encodedData: string): string => {
    if (!encodedData) throw new Error('No data to decode found')

    if (typeof atob === 'function') {
        return atob(encodedData)
    }

    const buff = Buffer.from(encodedData, 'base64')
    const decodedData = buff.toString('ascii')
    return decodedData
}

export const encodeBs64 = (data: string): string => {
    if (!data) throw new Error('No data to encode found')
    const buff = Buffer.from(data)
    const base64data = buff.toString('base64')
    return base64data
}
