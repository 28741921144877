import * as React from 'react'
import { getFormattedNumberWithCurrency } from '@/lib/util/currency'
import CrossIcon from 'icons/cross-icon'
import { colors } from 'theme'
import { ILineItemPrescription } from 'interfaces/order'
import globalManifest from 'data/global-manifest.json'

export interface IPrescriptionItemProps {
    title: React.ReactNode
    price: number
    currency?: string
    compareAtPrice?: number
    onRemove?: (lineItem?: ILineItemPrescription) => void
    isRemovable?: boolean
    quantity?: string
    className?: string
    showCompareLabel?: boolean
}

export interface IPrescriptionLineItemProps {
    lineItem: ILineItemPrescription
    onRemove?: IPrescriptionItemProps['onRemove']
    isRemovable?: IPrescriptionItemProps['isRemovable']
    showCompareLabel?: IPrescriptionItemProps['showCompareLabel']
}

const { resources: globalUIResources } = globalManifest

const priceComparisonLabelClassName = 'text-xs leading-120% -tracking-6'
const priceClassName = 'text-sm font-bold leading-140% -tracking-10 mt-0.5 block'

export function CartPrescriptionLineItem({
    lineItem,
    onRemove,
    isRemovable,
    showCompareLabel,
}: IPrescriptionLineItemProps) {
    const quantityLabel = lineItem.days_supply
        ? `${lineItem.days_supply} ${globalUIResources['rxDaysSupplyLabel'].value} (${lineItem.quantity} ${lineItem.drug_form_name})`
        : `${lineItem.quantity} ${lineItem.drug_form_name}`

    const totalPriceNum = Number(lineItem.total_price)
    const standardTotalPriceNum = Number(lineItem.standard_total_price)

    const compareAtPrice = standardTotalPriceNum > totalPriceNum ? standardTotalPriceNum : 0

    return (
        <PrescriptionItem
            title={lineItem.drug_name}
            quantity={quantityLabel}
            price={totalPriceNum}
            compareAtPrice={compareAtPrice}
            isRemovable={isRemovable}
            onRemove={onRemove}
            showCompareLabel={showCompareLabel}
        />
    )
}

export function PrescriptionItem({
    title,
    price,
    currency,
    compareAtPrice,
    isRemovable,
    quantity,
    onRemove,
    className = '',
    showCompareLabel,
}: IPrescriptionItemProps) {
    return (
        <div
            className={`px-5 py-3.5 pr-3 rounded-2.5xl bg-blue-rx flex flex-col gap-y-3 ${className}`}
            data-cy="prescription-line-item"
        >
            <div className={`flex justify-between gap-2.5 ${quantity ? '' : 'items-center'}`}>
                {/* min-w-0 needed on this div to allow flex shrink and prevent overflow */}
                <div className="min-w-0">
                    <div
                        className={`block truncate leading-140% -tracking-10.5 ${quantity ? 'text-sm' : ''}`}
                        data-cy="prescription-line-item-title"
                    >
                        {title}
                    </div>
                    {quantity && (
                        <div className="text-xsm text-gray-darker leading-140% truncate mt-0.5">{quantity}</div>
                    )}
                </div>
                <div className="flex flex-shrink-0 ml-auto">
                    <div
                        className={`wider ml-auto leading-140% grid gap-4 ${compareAtPrice > 0 ? 'grid-cols-2' : ''} ${
                            showCompareLabel ? 'self-end' : ''
                        }`}
                    >
                        {compareAtPrice > 0 && (
                            // min-w-0 needed on this div to allow flex shrink and prevent overflow
                            <div className="min-w-0 text-center">
                                {showCompareLabel && (
                                    <div className={`${priceComparisonLabelClassName} text-gray-darker`}>
                                        {globalUIResources['industryPriceLabel'].value}
                                    </div>
                                )}
                                <s
                                    className={`${priceClassName} text-gray-darker`}
                                    data-cy="prescription-compare-price"
                                >
                                    {getFormattedNumberWithCurrency(compareAtPrice)}
                                </s>
                            </div>
                        )}
                        <div className="text-center">
                            {showCompareLabel && (
                                <div className={priceComparisonLabelClassName}>
                                    {globalUIResources['cabinetPriceLabel'].value}
                                </div>
                            )}
                            <span className={priceClassName} data-cy="prescription-price">
                                {getFormattedNumberWithCurrency(price, currency)}
                            </span>
                        </div>
                    </div>
                </div>
                {isRemovable && (
                    <button
                        onClick={() => onRemove()}
                        className={`h-6 w-6 flex justify-center items-center flex-shrink-0 relative left-1 -ml-1 ${
                            showCompareLabel ? 'self-center' : 'self-start -mt-0.5'
                        }`}
                        data-cy="remove-line-item-button"
                    >
                        <CrossIcon stroke={colors.slateGray} strokeWidth={2} height="12" width="12" />
                    </button>
                )}
            </div>
        </div>
    )
}
